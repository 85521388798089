import React from "react"
import classNames from "classnames"
import {
  useIntl,
  createIntl,
} from "gatsby-plugin-intl"

import messages from "./messages"
import style from "./Details.module.css"

const Details = ({ dir, reverted }) => {
  const { locale } = useIntl()
  const intl = createIntl({ locale, messages: messages[locale] })

  return (
    <div
      className={classNames(style.details, style[dir], {
        [style.reverted]: reverted,
      })}
    >
      <div className={classNames(style.cover)} />
      <div className={classNames(style.content)}>
        <h3 id={dir}>{intl.formatMessage({ id: `${dir}.h` })}</h3>
        <p>
          <strong>{intl.formatMessage({ id: `${dir}.description` })}</strong>
        </p>
        {intl.formatMessage(
          {
            id: `${dir}.text`,
          },
          {
            ul: (...chunks) => (
              <ul className={classNames(style.list)}>{chunks}</ul>
            ),
            li: (...chunks) => <li key={chunks}><span>{chunks}</span></li>,
          }
        )}
      </div>
    </div>
  )
}

export default Details
