export default {
  en: {
    "flights.h": "Private Flights",
    "flights.description":
      "We are pleased to offer you a full range of services in the field of business aviation:",
    "flights.text":
      "<ul>" +
      "<li>Flights on comfortable business jets in any direction</li>" +
      "<li>Organization of ground handling at airports</li>" +
      "<li>Service in VIP rooms and VIP terminals</li>" +
      "<li>Ordering in-flight meal of the highest class</li>" +
      "<li>Accommodation in the best hotels in the world</li>" +
      "<li>Transfer to or from the airport for passengers using any car category</li>" +
      "<li>Helicopter flights, from comfortable business trips to search and rescue services</li>" +
      "</ul>",

    "medicine.h": "Medicine",
    "medicine.description":
      "Our company will provide you with comprehensive assistance in organizing medical care:",
    "medicine.text":
      "<ul>" +
      "<li>Medical evacuation - air transportation of patients of various degrees of disease severity accompanied by doctors</li>" +
      "<li>Ambulance call from the house to the airport and from the airport to the clinic</li>" +
      "<li>Arrangements with numerous leading clinics in the world.</li>" +
      "<li>The widest range of services in medical examination, treatment and prevention of diseases</li>" +
      "<li>Translation services in countries of treatment</li>" +
      "</ul>",

    "transport.h": "Transport",
    "transport.description":
      "Also, we are pleased to offer you a service for organizing transport for various purposes:",
    "transport.text":
      "<ul>" +
      "<li>Transfer</li>" +
      "<li>Air cargo worldwide</li>" +
      "<li>Ground transportation of goods</li>" +
      "<li>Customs clearance of goods</li>" +
      "<li>Cargo insurance</li>" +
      "</ul>",
  },
  ru: {
    "flights.h": "Частные перелеты",
    "flights.description": "Полный спектр услуг в сфере бизнес-авиации:",
    "flights.text":
      "<ul>" +
      "<li>Полеты на комфортабельных бизнес-джетах в любых направлениях</li>" +
      "<li>Организация наземного обслуживания в аэропортах</li>" +
      "<li>Обслуживание в ВИП-залах и ВИП-терминалах</li>" +
      "<li>Заказ бортового питания высшего класса</li>" +
      "<li>Размещение в лучших отелях мира</li>" +
      "<li>Трансфер в аэропорт или из аэропорта для пассажиров на любых категориях автомобилей</li>" +
      "<li>Полеты на вертолетах, от комфортабельных бизнес-поездок до поисково-спасательных работ</li>" +
      "</ul>",

    "medicine.h": "Медицина",
    "medicine.description":
      "Наша компания окажет Вам всестороннюю помощь в организации медицинского обслуживания:",
    "medicine.text":
      "<ul>" +
      "<li>Медицинская эвакуация – авиационная транспортировка пациентов разных степеней тяжести заболеваний в сопровождении врачей</li>" +
      "<li>Вызов скорой помощи от дома до аэропорта и от аэропорта до клиники</li>" +
      "<li>Договоренности с многочисленными передовыми клиниками мира</li>" +
      "<li>Широчайший спектр услуг в медицинском осмотре, лечении и профилактики заболеваний</li>" +
      "<li>Услуги переводчиков в странах проведения лечения</li>" +
      "</ul>",

    "transport.h": "Транспорт",
    "transport.description":
      "Кроме того мы рады предложить Вам сервис по организации транспорта для различных целей:",
    "transport.text":
      "<ul>" +
      "<li>Трансфер</li>" +
      "<li>Грузовые авиаперевозки по всему миру</li>" +
      "<li>Наземная транспортировка грузов</li>" +
      "<li>Таможенное оформление товаров</li>" +
      "<li>Страхование грузов</li>" +
      "</ul>",
  },
  fr: {
    "flights.h": "Vols privés",
    "flights.description":
      "Nous sommes ravis de vous offrir tout l’ensemble des services en aviation d’affaires:",
    "flights.text":
      "<ul>" +
      "<li>Vols aux business jets dans toutes les directions</li>" +
      "<li>Organisation de service terrestre dans les aéroports</li>" +
      "<li>Service dans les salles et les terminaux pour les personnes très notables</li>" +
      "<li>Commande de repas à bord de luxe</li>" +
      "<li>Logement dans les meilleurs hôtels mondiaux</li>" +
      "<li>Transfert des occupants à l’aéroport ou de l’aéroport dans les voitures de toute catégorie</li>" +
      "<li>Vols aux aéroneufs, des voyages d’affaires confortables aux travaux de recherche et de sauvetage</li>" +
      "</ul>",

    "medicine.h": "Médecine",
    "medicine.description":
      "Notre société vous prêtera toute assistance multiple en ce qui concerne l’organisation de service médical:",
    "medicine.text":
      "<ul>" +
      "<li>Evacuation médicale – transport par avion des patients au degré différent de gravité de maladie en accompagnement des médecins</li>" +
      "<li>Appel d’ambulance (domicile – aéroport et aéroport – clinique)</li>" +
      "<li>Conventions avec de nombreuses cliniques avancées mondiales</li>" +
      "<li>Large gamme des services en ce qui concerne l’examen médical, la thérapie et la prévention des maladies</li>" +
      "<li>Services des interprètes dans les pays de réalisation de thérapie</li>" +
      "</ul>",

    "transport.h": "Transport",
    "transport.description":
      "En outre, nous sommes ravis de vous offrir une organisation de transport dans des buts différents:",
    "transport.text":
      "<ul>" +
      "<li>Transfert</li>" +
      "<li>Trafic de frêt aérien dans le monde entier</li>" +
      "<li>Transport terrestre des charges</li>" +
      "<li>Formalités de douane des marchandises</li>" +
      "<li>Assurance des charges</li>" +
      "</ul>",
  },
  de: {
    "flights.h": "Private Flüge",
    "flights.description":
      "Wir freuen uns, Ihnen ein umfassendes Leistungsspektrum im Bereich Businessflüge anbieten zu können:",
    "flights.text":
      "<ul>" +
      "<li>Flüge mit komfortablen Business-Jets in jede Richtung</li>" +
      "<li>Organisation der Bodenabfertigung an Flughäfen</li>" +
      "<li>Service in VIP-Räumen und VIP-Terminals</li>" +
      "<li>Bestellung von Bordcatering der Spitzenklasse</li>" +
      "<li>Unterbringung in den besten Hotels der Welt</li>" +
      "<li>Transfer zum oder vom Flughafen für Passagiere mit jeder Fahrzeugkategorie</li>" +
      "<li>Hubschrauberflüge, von komfortablen Geschäftsreisen bis zur Suche und Rettung</li>" +
      "</ul>",

    "medicine.h": "Medizin",
    "medicine.description":
      "Unser Unternehmen unterstützt Sie umfassend bei der Organisation der medizinischen Versorgung:",
    "medicine.text":
      "<ul>" +
      "<li>Medizinische Evakuierung - Lufttransport von Patienten unterschiedlicher Schweregrade in Begleitung von Ärzten</li>" +
      "<li>Bestellung eines Krankenwagens von zu Hause zum Flughafen und vom Flughafen zur Klinik</li>" +
      "<li>Vereinbarungen mit zahlreichen führenden Kliniken der Welt</li>" +
      "<li>Die breiteste Palette von Dienstleistungen in der medizinischen Untersuchung, Behandlung und Prävention von Krankheitens</li>" +
      "<li>Übersetzungsdienste in Behandlungsländern</li>" +
      "</ul>",

    "transport.h": "Transport",
    "transport.description":
      "Darüber hinaus bieten wir Ihnen gerne einen Service für die Organisation von Transporten zu verschiedenen Zwecken an::",
    "transport.text":
      "<ul>" +
      "<li>Übertragung</li>" +
      "<li>Luftfrachtbeförderungen weltweit</li>" +
      "<li>Bodentransport von Waren</li>" +
      "<li>Zollabfertigung von Waren</li>" +
      "<li>Frachtversicherung</li>" +
      "</ul>",
  },
  it: {
    "flights.h": "Voli privati",
    "flights.description":
      "Siamo lieti di offrirLe una gamma completa di servizi nel campo dell'aviazione privata:",
    "flights.text":
      "<ul>" +
      "<li>Voli nei business-jet comodi in qualsiasi direzione</li>" +
      "<li>Organizzazione di servizi di terra negli aeroporti</li>" +
      "<li>Servizio in sale VIP e terminali VIP</li>" +
      "<li>Ordine di catering di bordo di altissima qualità</li>" +
      "<li>Alloggio nei migliori alberghi del mondo</li>" +
      "<li>Transfer nell'aeroporto o dall'aeroporto per i passeggeri nelle automobili di qualsiasi categoria</li>" +
      "<li>Voli negli elicotteri, dai viaggi di lavoro comodi alle operazioni di ricerca e salvataggio</li>" +
      "</ul>",

    "medicine.h": "Medicina",
    "medicine.description":
      "La nostra azienda Le fornirà assistenza completa nell'organizzazione di servizi sanitari:",
    "medicine.text":
      "<ul>" +
      "<li>Evacuazione medica – è il trasporto aereo dei pazienti con le malattie di vari livelli della gravità, accompagnati dai medici</li>" +
      "<li>Chiamata dell'ambulanza dalla casa all'aeroporto e dall'aeroporto alla clinica</li>" +
      "<li>Accordi con cliniche leader numerose del mondo</li>" +
      "<li>La più ampia varietà dei servizi di esame medico, trattamento e prevenzione di malattie</li>" +
      "<li>Servizi di traduzione nei paesi della realizzazione del trattamento</li>" +
      "</ul>",

    "transport.h": "Trasporto",
    "transport.description":
      "Inoltre, siamo lieti di offrirLe un servizio per l'organizzazione del trasporto per vari scopi:",
    "transport.text":
      "<ul>" +
      "<li>Transfer</li>" +
      "<li>Aerotrasporto di carico in tutto il mondo</li>" +
      "<li>Trasporto terrestre di carico</li>" +
      "<li>Stesura dei documenti doganali di merci</li>" +
      "<li>Assicurazione di merci</li>" +
      "</ul>",
  },
}
