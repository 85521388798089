import React from "react"


import Layout from "../components/Layout"
import Header from "../components/Header"
import Details from "../components/Details"
import Footer from "../components/Footer"

const IndexPage = () => {
  const dirs = ["flights", "medicine", "transport"]

  return (
    <Layout>
      <Header dirs={dirs} />
      <main>
        {dirs.map((dir, i) => (
          <Details key={dir} dir={dir} reverted={!(i % 2)} />
        ))}
      </main>
      <Footer />
    </Layout>
  )
}

export default IndexPage
