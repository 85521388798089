import React, { useState } from "react"
import { useIntl, changeLocale, IntlContextConsumer, Link } from "gatsby-plugin-intl"
import classNames from "classnames"

import Cover from "./Cover"
import "./Header.css"
import logo from "./logo.png"

const Header = ({ dirs }) => {
  const [activeCoverKey, setActiveCoverKey] = useState()
  const intl = useIntl()
  const handleBlockEnter = key => () => {
    setActiveCoverKey(key)
  }
  const handleBlockLeave = () => setActiveCoverKey()

  return (
    <header>
      <Cover activeCoverKey={activeCoverKey} />
      <div className="langs">
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <a
                key={language}
                className={classNames({ active: currentLocale === language })}
                onClick={() => changeLocale(language)}
              >
                {language}
              </a>
            ))
          }
        </IntlContextConsumer>
      </div>
      <div className="name">
        <img src={logo} alt="psm" className="logo" />
        <h1>{intl.formatMessage({ id: "title" })}</h1>
      </div>
      <div className="dirs">
        {dirs.map(dir => (
          <Link
            key={dir}
            onMouseEnter={handleBlockEnter(dir)}
            onMouseLeave={handleBlockLeave}
            to={`/#${dir}`}
          >
            <h2>{intl.formatMessage({ id: `header.${dir}.h` })}</h2>
            <hr />
            <div className={classNames("text")}>
              {intl.formatMessage({ id: `header.${dir}.text` })}
            </div>
          </Link>
        ))}
      </div>
    </header>
  )
}

export default Header
