import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import style from "./Footer.module.css"
import logo from "./logo.png"

const Footer = () => {
  const intl = useIntl()

  return (
    <footer>
      <div className={style.name}>
        <img src={logo} alt="psm" className={style.logo} />
        <span>{intl.formatMessage({ id: "title" })}</span>
      </div>

      <div>
        <h2>{intl.formatMessage({ id: `address.h` })}</h2>
        Riva Paradiso 2, Palazzo Mantegazza, 6900 Paradiso, Switzerland
      </div>
      <div>
        <h2>{intl.formatMessage({ id: `bank.h` })}</h2>
        <p>
          {intl.formatMessage({ id: `bank.account` })}  <br />
          {intl.formatMessage({ id: `bank.account` })}  <br />
          {intl.formatMessage({ id: `bank.account` })}  
        </p>
        <p>
          {intl.formatMessage({ id: `bank.bank` })}: Raiffeisen Schweiz Genossenschaft Raiffeisenplatz 9001 St.
          Gallen, Switzerland SWIFT-BIC: RAIFCH22XXX
        </p>
        <p>{intl.formatMessage({ id: `bank.postal` })}:  </p>
      </div>

      <div>
        <h2>{intl.formatMessage({ id: `contacts.h` })}</h2>
        <p>
          <a href="tel:+41793471473">+ 41 79 347 14 73</a>
          <br />
          <a href="tel:+41919804083">+ 41 91 980 40 83</a>
        </p>
        <p>
          <a href="mailto:info@psmgroup.ch">info@psmgroup.ch</a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
