import React from "react"
import classNames from "classnames"

import style from "./Cover.module.css"

const covers = ["default", "flights", "medicine", "transport"]

const Cover = ({ activeCoverKey = "default" }) =>
  covers.map(cover => (
    <div
      key={cover}
      className={classNames(style.cover, style[cover], {
        [style.active]: activeCoverKey === cover,
      })}
    />
  ))

export default Cover
